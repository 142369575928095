import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { fontStyle } from '../../styles/styles';
import { Link } from 'gatsby';

const Footer = () => {
    return (
        <div>
            <footer className="bg-primary-light">
                <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                        <div>
                            <div className="text-teal-600">
                                <StaticImage src='../../assets/logo/synergyLogoWhite.png' alt='' className='object-cover w-[130px]' />
                            </div>

                            <div className="mt-8 max-w-xs text-gray-500">
                                <div className="flex items-start">
                                    <StaticImage className='w-[25px]' src="../../assets/icons/locationUnfilledIconco.png" alt='' />
                                    <p className={`${fontStyle} text-white !font-thin !text-[13px] ml-4 leading-6`}>Synergy Primary Clinic <br />2107 Eldorado Pkwy<br /> Suite 106<br />McKinney, TX 75070</p>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-3">
                            <div>
                                <p className="font-bold text-white text-[18px]">Explore</p>
                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <Link to='/' className="!text-white font-thin transition hover:opacity-75"> Home </Link>
                                    </li>
                                    <li>
                                        <Link to='/about-us' className="!text-white font-thin transition hover:opacity-75"> About Us </Link>
                                    </li>
                                    <li>
                                        <Link to='/testimonials' className="!text-white font-thin transition hover:opacity-75"> Testimonials </Link>
                                    </li>
                                    <li>
                                        <Link to='/blog' className="!text-white font-thin transition hover:opacity-75"> Articles </Link>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <p className="font-bold text-white text-[18px]">Services</p>
                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <Link to='/services/diabetes' className="!text-white font-thin transition hover:opacity-75"> Diabetes </Link>
                                    </li>
                                    <li>
                                        <Link to='/services/family-medicine' className="!text-white font-thin transition hover:opacity-75"> Family Medicine </Link>
                                    </li>
                                    <li>
                                        <Link to='/services/blood-pressure' className="!text-white font-thin transition hover:opacity-75"> High Blood Pressure </Link>
                                    </li>
                                    <li>
                                        <Link to='/services/men-health' className="!text-white font-thin transition hover:opacity-75"> Men's Health </Link>
                                    </li>
                                    <li>
                                        <Link to='/services/primary-care' className="!text-white font-thin transition hover:opacity-75"> Primary Care </Link>
                                    </li>
                                    <li>
                                        <Link to='/services/std-testing' className="!text-white font-thin transition hover:opacity-75"> STD Testing </Link>
                                    </li>
                                    <li>
                                        <Link to='/services/weight-loss' className="!text-white font-thin transition hover:opacity-75"> Weight Loss </Link>
                                    </li>
                                    <li>
                                        <Link to='/services/women-health' className="!text-white font-thin transition hover:opacity-75"> Women's Health </Link>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <p className="font-bold text-white text-[18px]">Book an appointment</p>
                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <a href='tel:469-919-0003' className='pointer flex items-center' aria-label='phone'>
                                            <span className='block flex items-center justify-center rounded-full !w-[30px] !h-[30px] bg-white mr-3'>
                                                <StaticImage src='../../assets/icons/phoneIconBlack.svg' alt='' />
                                            </span>
                                            <p className={`${fontStyle} !text-white !font-thin`}>469-919-0003</p>
                                        </a>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <span className='block flex items-center justify-center rounded-full !w-[30px] !h-[30px] bg-white mr-3'>
                                                <StaticImage className='w-[15px] h-[15px]' src='../../assets/icons/fax-machine.png' alt='' />
                                            </span>
                                            <p className={`${fontStyle} !text-white !font-thin`}>469-422-6097</p>
                                        </div>
                                    </li>
                                    <li>
                                        <Link to='/appointment'><button className="bg-transparent transition-all hover:bg-white text-white font-normal hover:text-black py-3 px-5 border border-white hover:border-transparent rounded-full mt-3">Request an appointment</button></Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <footer className="bg-white shadow">
                <div className="w-full max-w-screen-xl mx-auto p-4 md:py-6">
                    <div className="sm:flex sm:items-center sm:justify-center">
                        <ul className="flex flex-wrap items-center justify-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-primary-lighter">
                            <li className="p-2">
                                <span className="me-4 md:me-6">© Copyright 2024</span>
                            </li>
                            <li className="p-2">
                                <Link to='/privacy-policy' className="hover:underline me-4 md:me-6">Privacy Policy</Link>
                            </li>
                            <li className="p-2">
                                <Link to='/terms-conditions' className="hover:underline me-4 md:me-6">Terms & Conditions</Link>
                            </li>
                            <li className="p-2">
                                <Link to='/accessibility-notice' className="hover:underline">Accessibility Notice</Link>
                            </li>
                        </ul>
                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-300 lg:my-6" />
                    <p className="block text-sm text-gray-500 text-center dark:text-primary-lighter font-medium">Developed & maintained by Technfo LLC </p>
                </div>
            </footer>
        </div>
    );
};

export default Footer;